import { Controller } from "@hotwired/stimulus";
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default/icons'
import 'tinymce/themes/silver/theme';
import 'tinymce/models/dom/index.js';
// Import plugins
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
// import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
// import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

export default class extends Controller {
  static targets = ['input']

  initialize () {
    this.defaults = {
      content_css: false,
      skin: false,
      toolbar: [
        'undo redo | bold italic underline strikethrough | blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap | fullscreen  preview | image media template link anchor codesample | ltr rtl'
              ],
      // mobile: {
      //   toolbar: [
      //     'styleselect | bold italic underline strikethrough superscript',
      //     'blockquote numlist bullist link | alignleft aligncenter alignright | table',
      //     'undo redo | preview code help'
      //   ]
      // },
      plugins: 'preview searchreplace autolink autosave directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars',
      menubar: false,
      style_formats: [
        { title: 'Heading 1', format: 'h1' },
        { title: 'Heading 2', format: 'h2' },
        { title: 'Heading 3', format: 'h3' },
        { title: 'Heading 4', format: 'h4' },
        { title: 'Heading 5', format: 'h5' },
        { title: 'Heading 6', format: 'h6' },
        { title: 'Paragraph', format: 'p'}
      ],
      // max_height: 700,
      default_link_target: '_blank',
      link_title: false,
      autoresize_bottom_margin: 10,
      link_context_toolbar: true,
      relative_urls: false,
      browser_spellcheck: true,
      element_format: 'html',
      invalid_elements: 'span',
      content_style: 'html { font-family: Roboto, sans-serif; line-height: 1.5; }'
    }
  }

  connect () {
    let config = Object.assign({ target: this.inputTarget }, this.defaults)
    tinymce.init(config)
  }

  disconnect () {
    tinymce.remove()
  }
}

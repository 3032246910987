import { Controller } from "@hotwired/stimulus"
import tomSelect from "tom-select"

export default class extends Controller {
  static targets = ['field'];

  connect() {
    const selectInput = this.fieldTarget;

    if (selectInput) {
      new tomSelect(selectInput)
    }
  }
}

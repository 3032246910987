import { Controller } from "@hotwired/stimulus";
import Tagify from '@yaireo/tagify';

export default class extends Controller {
  connect() {
    var elem = this.element
    var tagify = new Tagify(elem, {
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(',')
    })
    console.log(tagify)
  }
}

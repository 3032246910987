import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "output"]

    preview() {
        var input = this.inputTarget
        
        var files = input.files
        var imgLoc = this.outputTarget
        for (var i = 0; i < files.length; i++) {
            let reader = new FileReader()
            reader.onload = function() {
                let div = document.createElement("div")
                div.className = "col-3"
                let image = document.createElement("img")
                div.appendChild(image)
                imgLoc.appendChild(div)
                //image.style.height = '300px'
                image.className = 'img-thumbnail'
                image.src = reader.result
            }
            reader.readAsDataURL(files[i])
        }
    }
}